@import '../../common.scss';

.Professional {
  padding-top: $space_xl;
}

.Name {
  @include fs_h_large;
  margin-bottom: $space_s;
}

.Profession {
  @include fs_p_normal;
  margin-bottom: $space_xl;
}

.Question {
  @include fs_h_small;
  margin-bottom: $space_l;
}

.Avatar {
  height: 125px;
  width: 125px;
  border-radius: 50%;
  vertical-align: middle;
  margin-bottom: $space_xl;
  background-color: $secondary;
}

.AnswerContainer {
  display: flex;
  align-items: center;
  justify-content: center;
}

.Answer {
  @include fs_p_normal;
}

.YesAnswer {
  @extend .Answer;
  color: $secondary;
  text-transform: uppercase;
  font-weight: bold;
}

.NoAnswer {
  @extend .Answer;
  color: $primary;
  text-transform: uppercase;
  font-weight: bold;
}

.Reasoning {
  @include fs_p_normal;
  text-align: left;
  margin: $space_l 0;
  white-space: pre-wrap;
}

.Timestamp {
  @include fs_p_normal;
  text-align: left;
  margin-bottom: $space_l;
}
