@import '../../_common.scss';

.Label {
  @include fs_p_normal;
  text-align: center;
  margin-top: $space_m;
  margin-bottom: $space_xs;
}

.ApproveSection {
  display: inline-block;
  text-align: center;
  color: white;
}

.DisapproveSection {
  display: inline-block;
  text-align: center;
  color: white;
}

.OverflowRight > p,
.OverflowLeft > p,
.DisapproveSection > p,
.ApproveSection > p {
  @include fs_medium_bold;
  font-weight: bold;
  padding: 5px;
}

.Bar {
  flex: 1 1 auto;
  border-radius: 50px;
}

.BarContainer {
  display: flex;
  flex-direction: row;
}

.OverflowRight {
  width: 45px;
  padding-left: 5px;
  text-align: left;
}

.OverflowLeft {
  width: 45px;
  padding-right: 5px;
  text-align: right;
}

.Percentage {
  @include fs_medium_bold;
  word-wrap: normal;
}
