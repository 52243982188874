@import '../_common.scss';

.Container {
  padding: $space_l;
  padding-bottom: $space_xl;
  max-width: 460px;
  height: auto;
  text-align: center;
  width: 100%;
}
