@import '../../common.scss';

.Container {
  text-align: left;
}

.Title {
  @include fs_h_large;
  margin: $space_l 0;
}
