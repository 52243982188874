@import '../../_common.scss';

.SolidButton {
  @extend .Button;
  user-select: none;
  border-radius: 5px;
  color: #fff;
  max-width: 176px;
  min-width: 130px;
  border: 0;
  padding: $space_m;
}

.Text {
  @include fs_p_bold;
  text-transform: uppercase;
}

.SolidButton:active {
  transform: translate(0, 3px);
}

.Submit {
  background-color: $secondary;
}

.DisabledSubmit {
  background-color: rgba($secondary, 0.2);
}

.Cancel {
  background-color: $primary;
}

.DisabledCancel {
  background-color: rgba($primary, 0.2);
}

.Neutral {
  background-color: $accent_1;
}

.DisabledNeutral {
  background-color: $accent_2;
  pointer-events: none;
}

.Content {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.Icon {
  padding-left: $space_m;
}
