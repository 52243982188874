@import '../../_common.scss';

.QuestionContainer {
  margin: $space_l 0;
}

.ButtonContainer {
  display: flex;
  justify-content: center;
  padding-bottom: $space_xl;
}

.PadLeft {
  margin-left: $space_s;
}

.PadRight {
  margin-right: $space_s;
}

.AdditionalInfoHeader {
  @include fs_p_bold;
  margin-bottom: $space_l;
}

.Subtitle {
  @include fs_p_normal;
}

.HeroContainer {
  display: flex;
  align-items: center;
  justify-content: center;

  @media (min-width: 360px) {
    justify-content: space-between;
  }
}

.TopNavButton {
  height: 40px;
  width: 40px;
}

.HiddenTopButton {
  visibility: hidden;
}

.RightNavButton {
  transform: rotateY(180deg);
}

.BackDrop {
  background-color: rgba(0, 0, 0, 0.15);
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1;
}

.Subtitle {
  @include fs_p_normal;
  margin-top: $space_m;
}

.NextButton {
  margin: $space_m * 2 0;
  width: 180px;
}
