@import '_common.scss';

.App {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  background-color: $background;

  *,
  *::before,
  *::after {
    -webkit-box-sizing: inherit;
    -moz-box-sizing: inherit;
    box-sizing: inherit;
  }

  font-family: 'Open Sans', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: $content;

  display: flex;
  flex-direction: column;
  align-items: center;
  overflow-x: hidden;
}

.Content {
  flex: 1 0 auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
}
