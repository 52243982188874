@import '../../_common.scss';

.Container {
  padding: $space_l 0;
}

.Label {
  @include fs_p_bold;
  text-align: center;
  margin-bottom: $space_xs;
  text-transform: uppercase;
  word-wrap: normal;
}

.ApproveSection {
  display: inline-block;
  text-align: center;
  color: white;
}

.DisapproveSection {
  display: inline-block;
  text-align: center;
  color: white;
}

.OverflowRight > p,
.OverflowLeft > p,
.DisapproveSection > p,
.ApproveSection > p {
  @include fs_medium_bold;
  font-weight: bold;
  padding: 5px;
}

.Bar {
  flex: 1 1 auto;
  border-radius: 100px;
}

.BarContainer {
  display: flex;
  flex-direction: row;
}

.OverflowRight {
  width: 45px;
  padding-left: 5px;
  text-align: left;
}

.OverflowLeft {
  width: 45px;
  padding-right: 5px;
  text-align: right;
}

.Percentage {
  @include fs_medium_bold;
  word-wrap: normal;
}

.SummaryTitle {
  @include fs_p_normal;
  padding-bottom: $space_s;
}

.Summary {
  @include fs_p_normal;
  border-radius: 5px;
  padding: $space_m $space_l;
  margin-bottom: $space_s;
}

.ApproveSummary {
  @extend .Summary;
  background-color: rgba(233, 46, 46, 0.1);
}

.DisapproveSummary {
  @extend .Summary;
  background-color: #e4f4ff;
}

.NoSummaries {
  @extend .Summary;
  background-color: rgba(47, 46, 65, 0.1);
}

.ArrowContainer {
  width: 100%;
  display: flex;
  padding-top: $space_l;
}

.Arrow {
  width: 42px;
  height: 42px;
  margin-top: -$space_l;
}

.HiddenArrow {
  visibility: hidden;
}
