@import '../../common.scss';

.Footer {
  background-color: $accent_3;
  width: 100%;
  flex-shrink: 0;
}

.Container {
  margin: 0 auto;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: $space_l;

  @media (min-width: 375px) {
    padding: $space_l $space_xl;
  }
}

.FirstLink {
  @extend .A;
  @include fs_p_normal;

  @media (min-width: 375px) {
    padding-right: $space_l;
  }
}

.Link {
  @extend .Button;
  @include fs_p_normal;

  @media (min-width: 375px) {
    padding-right: $space_l;
  }
}

.LastLink {
  @extend .Link;
  padding-right: 0;
}
