@import '../../common.scss';

.Container {
  margin-top: $space_xl;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
}

.Avatars {
  display: flex;
  flex-wrap: wrap;
  max-width: calc(((100vw - #{$space_l} * 2) / 2) - #{$space_xs});

  @media (min-width: 460px) {
    max-width: (460px - $space_l * 2) / 2 - $space_xs;
  }
}
