.yle__section {
  margin-bottom: 0;
}

.yle__app > .yle__navigation {
  margin-bottom: 0;
}

.yle__footer {
  position: relative;
}

.yle__app {
  padding-bottom: 0 !important;
}
