@import '../../common.scss';

.Container {
  section {
    border-bottom: 1px solid $accent_2;
  }

  section:last-of-type {
    border-bottom: none;
  }

  button {
    border: none;
  }

  padding-top: $space_xl;
}

$max_nav_width: 460px - $space_l * 2;

.BackArrow {
  @extend .Button;
  position: fixed;
  background-color: $accent_3;
  padding: $space_m;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.1);
  border-radius: 5px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  max-width: $max_nav_width;
  width: calc(100vw - 32px);
  left: 50%;
  transform: translate(-50%, -32px);
}

.NavTitle {
  @include fs_p_bold;
  text-transform: uppercase;
}

.ArrowIcon {
  width: 30px;
}

.NavLeft {
  visibility: hidden;
  width: 30px;
}
