@import '../../common.scss';

.Thumb {
  position: absolute;
  right: 0;
  bottom: 0;
}

.Avatar {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  margin: $space_xs;
  position: relative;
  background-position: top center;
  background-repeat: no-repeat;
  background-size: cover;
  background-color: $background;
}
