@import '../../common.scss';

.Title {
  @include fs_h_small;
  margin: $space_l 0;
}

.Question {
  @include fs_h_small;
  margin: $space_l 0;
  margin-top: $space_xl;
}

.Subtitle {
  @include fs_p_normal;
}

.HeroContainer {
  display: flex;
  align-items: center;
  justify-content: center;

  @media (min-width: 360px) {
    justify-content: space-between;
  }
}

.TopNavButton {
  height: 40px;
  width: 40px;
}

.Answer {
  @include fs_p_normal;
}

.YesAnswer {
  @include fs_p_bold;
  text-transform: uppercase;
  color: $secondary;
}

.NoAnswer {
  @include fs_p_bold;
  text-transform: uppercase;
  color: $primary;
}

.BackDrop {
  background-color: rgba(0, 0, 0, 0.15);
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

.Hightlight {
  background-color: $accent_6;
  padding: 0 $space_xs;
}

.StartButton {
  margin-top: $space_xl;
}
