@import '../../_common.scss';

.HideExperts {
  padding-top: $space_xl;
}

.Description {
  @include fs_p_normal;
  margin-bottom: $space_l;
}

.ButtonTitle {
  @include fs_p_bold;
  text-transform: uppercase;
}

.ExpertButton {
  @extend .Button;
  color: $secondary;
  border-radius: 5px;
  border: 1px solid $secondary;
  padding: $space_m $space_l;
}
