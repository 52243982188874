@import '../../common.scss';

.Section {
  margin: $space_xl 0;
  display: flex;
  align-items: flex-start;
}

.Label {
  @include fs_p_bold;
}

.Icon {
  width: 60px;
  height: 60px;
}

.Content {
  @include fs_p_normal;
  padding-left: $space_l;
}

.Authors {
  @include fs_p_normal;
}
