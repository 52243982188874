@import '../../common.scss';

.Statement {
  @include fs_h_medium;
  margin: $space_xl 0;
}

.Token {
  color: $secondary;
  cursor: pointer;
}
