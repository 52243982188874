@import '../../common.scss';

.Background {
  height: 40vh;
}

.Container {
  padding-top: 0;
  text-align: left;
  margin-bottom: $space_xl;
  width: 100%;
}

.Content {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  flex-direction: column;

  p {
    padding-bottom: $space_xl;
    width: 100%;
  }

  button {
    align-self: center;
  }
}

.ArrowButton {
  background-color: transparent;
}

.Title {
  @include fs_h_front;
  padding: $space_s $space_m;
  background-color: $accent_4;
  color: $background;
  text-transform: uppercase;
  text-align: center;
}

.SubTitle {
  @include fs_h_medium;
  text-align: center;
  width: 100%;
  font-size: 17px;
  line-height: 25px;

  @media (min-width: 375px) {
    font-size: 20px;
    line-height: 26px;
  }

  @media (min-width: 430px) {
    font-size: 24px;
    line-height: 32px;
  }
}

.Description {
  @include fs_p_normal;
  white-space: pre-wrap;
}

.Link {
  @include fs_p_normal;
  color: $accent_5;
  text-decoration: none;
  text-align: center;
  padding-top: $space_xl;
  padding-bottom: $space_l;
  width: 100%;
}

.YleNewsLab {
  height: 30px;
}
