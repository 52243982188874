@import '../../common.scss';

$max_nav_width: 460px - $space_l * 2;

.Container {
  border-radius: 5px;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.1);
  padding: $space_xl $space_l;
  background-color: $background;

  position: fixed;
  top: 50%;
  text-align: center;

  max-width: $max_nav_width;
  width: calc(100vw - 32px);
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 1;
}

.Text {
  @include fs_p_normal;
  margin-bottom: $space_l;
}

.Link {
  @include fs_p_normal;
  margin-bottom: $space_l;
  text-decoration: none;
  color: $secondary;
  display: block;
}

.CloseButton {
  @extend .Button;
  background-color: $secondary;
  padding: $space_m;
  color: $background;
  border-radius: 5px;
  @include fs_p_bold;
  text-transform: uppercase;
  width: 150px;
}
