@import '../../common.scss';

.Container {
  text-align: left;
}

.Ingress {
  @include fs_p_normal;
}

.QuestionTitle {
  @include fs_p_bold;
  margin: $space_l 0;
}

.Answer {
  @include fs_p_normal;
}

.Link {
  @include fs_p_normal;
  text-decoration: none;
  color: $secondary;
  display: block;
  margin-top: $space_l;
  width: 100%;
}
