$space_xs: 4px;
$space_s: 8px;
$space_m: 16px;
$space_l: 24px;
$space_xl: 40px;
$space_xxl: 64px;

$accent_1: #2f3a56;
$accent_2: #cecece;
$accent_3: #e2f2ff;
$accent_4: #201b96;
$accent_5: #2299fd;
$accent_6: #eaf7ff;
$accent_7: #f8fbff;
$primary: #e92e2e;
$secondary: #3e92de;
$background: #fff;
$content: #000;

$breakpoint_tablet: 768px;

// font style mixins
@mixin fs_h_front {
  font-family: 'din-2014', sans-serif;
  font-style: normal;
  font-weight: 800;
  font-size: 24px;
  line-height: 32px;
  margin: 0;
  margin-bottom: $space_s;

  @media (min-width: 375px) {
    font-size: 28px;
    line-height: 39px;
  }

  @media (min-width: 430px) {
    font-size: 36px;
    line-height: 44px;
  }
}

// yle h2
@mixin fs_h_large {
  font-size: 28px;
  line-height: 39px;
  font-weight: bold;
  margin: 0;
}

// yle h3
@mixin fs_h_medium {
  font-size: 24px;
  line-height: 32px;
  font-weight: bold;
  margin: 0;
  margin-bottom: $space_l;
}

// yle h4
@mixin fs_h_small {
  font-size: 20px;
  line-height: 26px;
  font-weight: bold;
  margin: 0;
}

// yle p
@mixin fs_p_normal {
  font-size: 17px;
  line-height: 25px;
  font-weight: normal;
  margin: 0;
}

// yle p bold
@mixin fs_p_bold {
  @include fs_p_normal;
  font-weight: bold;
}

// yle medium
@mixin fs_medium {
  font-size: 14px;
  line-height: 20px;
  font-weight: normal;
  margin: 0;
}

// yle medium bold
@mixin fs_medium_bold {
  @include fs_medium;
  font-weight: bold;
}

.NavButton {
  height: 50px;
  width: 165px;
  border: none;
  @include fs_p_normal;
}

@mixin paragraph_l {
  font-size: 17px;
  line-height: 19px;
}

.HeroIllustration {
  height: 250px;
}

.Button {
  font-family: 'Open Sans', sans-serif;
  border: none;
  background-color: transparent;
  padding: 0;
  margin: 0;
  cursor: pointer;
}

.A {
  text-decoration: none;
  color: $content;
}
